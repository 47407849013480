import React, { lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import FourOFour from './pages/404';
import Loading from './components/Loading';

const LazyAnalysis = lazy(() => import('./pages/Analysis'));
const LazyProjects = lazy(() => import('./pages/Projects'));
const LazyToolkit = lazy(() => import('./pages/Toolkit'));
const LazyInstuments = lazy(() => import('./pages/Toolkits/SetofInstruments'));
const LazyCGovSAN = lazy(() => import('./pages/Toolkits/CGovSAN'));
const LazyResources = lazy(() => import('./pages/Resources'));
const LazyDocuments = lazy(() => import('./pages/Resources/Documents'));
const LazyDocumentDetail = lazy(() => import('./pages/Resources/DocumentDetail'));
const LazyMaps = lazy(() => import('./pages/Resources/MapStatic'));
const LazyVideos = lazy(() => import('./pages/Resources/Videos'));
const LazyTopics = lazy(() => import('./pages/Topics'));
const LazyEvents = lazy(() => import('./pages/Events'));
const LazyOpportunities = lazy(() => import('./pages/Opportunity'));
const LazyPosts = lazy(() => import('./pages/Posts'));
const LazyAbout = lazy(() => import('./pages/About'));
const LazyPartners = lazy(() => import('./pages/Partners'));
const LazyEventPage = lazy(() => import('./pages/EventPage'));
const LazyLogin = lazy(() => import('./pages/Login'));
const LazyDashboard = lazy(() => import('./pages/Dashboard'));
const LazyQuestionnaire = lazy(() => import('./pages/Questionnaire')); // Import the Questionnaire page

export default function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [role, setRole] = useState(null); // Track the user's role
  const [country, setCountry] = useState(null); // Track the user's country
  const [submittedResponses, setSubmittedResponses] = useState(null); // Store the submitted responses

  const handleLogin = (status, userRole, userCountry) => {
    setIsLoggedIn(status);
    setRole(userRole); // Set the user role on login
    setCountry(userCountry); // Set the user's country on login
  };

  const handleQuestionnaireSubmit = (responses) => {
    console.log('Questionnaire responses submitted:', responses); // Debugging
    setSubmittedResponses(responses); // Store the submitted questionnaire responses
  };

  console.log('isLoggedIn:', isLoggedIn);
  console.log('submittedResponses:', submittedResponses);
  console.log('country:', country); // Add a log to check the country

  return (
    <div>
      <Router basename="/">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route 
            path="/login" 
            element={isLoggedIn ? <Navigate to="/questionnaire" replace /> : <Suspense fallback={<Loading />}><LazyLogin onLogin={handleLogin} /></Suspense>} 
          />
          <Route 
            path="/questionnaire/:section" 
            element={isLoggedIn ? 
              <Suspense fallback={<Loading />}>
                {/* Pass country and role to Questionnaire */}
                <LazyQuestionnaire 
                  role={role} 
                  country={localStorage.getItem('country')} 
                  onSubmit={handleQuestionnaireSubmit} 
                />
              </Suspense> : 
              <Navigate to="/login" replace />
            } 
          />
          <Route 
            path="/dashboard" 
            element={isLoggedIn && submittedResponses ? 
              <Suspense fallback={<Loading />}>
                {/* Pass responses and country to Dashboard */}
                <LazyDashboard responses={submittedResponses} country={country} />
              </Suspense> : 
              <Navigate to="/login" replace />
            }
          />
          <Route path="/analysis-and-response" element={<Suspense fallback={<Loading />}><LazyAnalysis /></Suspense>} />
          <Route path="/analysis" element={<Suspense fallback={<Loading />}><LazyAnalysis /></Suspense>} />
          <Route path="/analysis-and-response/analysis" element={<Navigate to="/analysis" />} />
          <Route path="/response" element={<Suspense fallback={<Loading />}><LazyProjects /></Suspense>} />
          <Route path="/analysis-and-response/response" element={<Navigate to="/response" />} />
          <Route path="/analysis-and-response/toolkit" element={<Suspense fallback={<Loading />}><LazyToolkit /></Suspense>} />
          <Route path="/analysis-and-response/toolkit/set-of-instruments" element={<Suspense fallback={<Loading />}><LazyInstuments /></Suspense>} />
          <Route path="/analysis-and-response/toolkit/c-gov-san" element={<Suspense fallback={<Loading />}><LazyCGovSAN /></Suspense>} />
          <Route path="/resources" element={<Suspense fallback={<Loading />}><LazyResources /></Suspense>} />
          <Route path="/resources/documents" element={<Suspense fallback={<Loading />}><LazyDocuments /></Suspense>} />
          <Route path="/documents/:bllink" element={<Suspense fallback={<Loading />}><LazyDocumentDetail /></Suspense>} />
          <Route path="/resources/maps" element={<Suspense fallback={<Loading />}><LazyMaps /></Suspense>} />
          <Route path="/resources/multimedia" element={<Suspense fallback={<Loading />}><LazyVideos /></Suspense>} />
          <Route path="/topics" element={<Suspense fallback={<Loading />}><LazyTopics /></Suspense>} />
          <Route path="/event-and-opportunities" element={<Suspense fallback={<Loading />}><LazyEvents /></Suspense>} />
          <Route path="/event-and-opportunities/event" element={<Suspense fallback={<Loading />}><LazyEvents /></Suspense>} />
          <Route path="/event-and-opportunities/opportunities" element={<Suspense fallback={<Loading />}><LazyOpportunities /></Suspense>} />
          <Route path="/about" element={<Navigate to="/about/who-are-we" />} />
          <Route path="/about/members" element={<Suspense fallback={<Loading />}><LazyPartners /></Suspense>} />
          <Route path="/about/:permalink" element={<Suspense fallback={<Loading />}><LazyAbout /></Suspense>} />
          <Route path="/post/:permalink" element={<Suspense fallback={<Loading />}><LazyPosts /></Suspense>} />
          <Route path="/event-and-opportunities/event/:year/:permalink" element={<Suspense fallback={<Loading />}><LazyEventPage /></Suspense>} />

          {/* Add a 404 route */}
          <Route path="*" element={<FourOFour />} />
        </Routes>
      </Router>
    </div>
  );
};